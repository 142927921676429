/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { CutOffTime } from './CutOffTime';
import {
    CutOffTimeFromJSON,
    CutOffTimeFromJSONTyped,
    CutOffTimeToJSON,
} from './CutOffTime';
import type { LightProductContent } from './LightProductContent';
import {
    LightProductContentFromJSON,
    LightProductContentFromJSONTyped,
    LightProductContentToJSON,
} from './LightProductContent';
import type { PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsDropOff } from './PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsDropOff';
import {
    PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsDropOffFromJSON,
    PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsDropOffFromJSONTyped,
    PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsDropOffToJSON,
} from './PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsDropOff';
import type { PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsMeeting } from './PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsMeeting';
import {
    PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsMeetingFromJSON,
    PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsMeetingFromJSONTyped,
    PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsMeetingToJSON,
} from './PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsMeeting';
import type { TravelersFields } from './TravelersFields';
import {
    TravelersFieldsFromJSON,
    TravelersFieldsFromJSONTyped,
    TravelersFieldsToJSON,
} from './TravelersFields';

/**
 * 
 * @export
 * @interface LightTour
 */
export interface LightTour {
    /**
     * 
     * @type {string}
     * @memberof LightTour
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LightTour
     */
    operatorId: string;
    /**
     * 
     * @type {boolean}
     * @memberof LightTour
     */
    active: boolean;
    /**
     * 
     * @type {PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsMeeting}
     * @memberof LightTour
     */
    meeting?: PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsMeeting;
    /**
     * 
     * @type {PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsDropOff}
     * @memberof LightTour
     */
    dropOff?: PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsDropOff | null;
    /**
     * 
     * @type {number}
     * @memberof LightTour
     */
    maxTravelersPerReservation: number;
    /**
     * 
     * @type {number}
     * @memberof LightTour
     */
    minTravelersPerTour?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LightTour
     */
    maxTravelersPerTour: number;
    /**
     * 
     * @type {CutOffTime}
     * @memberof LightTour
     */
    cutOffTime?: CutOffTime;
    /**
     * 
     * @type {Currency}
     * @memberof LightTour
     */
    currency: Currency;
    /**
     * 
     * @type {TravelersFields}
     * @memberof LightTour
     */
    travelersFields?: TravelersFields;
    /**
     * 
     * @type {LightProductContent}
     * @memberof LightTour
     */
    productContent: LightProductContent;
}

/**
 * Check if a given object implements the LightTour interface.
 */
export function instanceOfLightTour(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "operatorId" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "maxTravelersPerReservation" in value;
    isInstance = isInstance && "maxTravelersPerTour" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "productContent" in value;

    return isInstance;
}

export function LightTourFromJSON(json: any): LightTour {
    return LightTourFromJSONTyped(json, false);
}

export function LightTourFromJSONTyped(json: any, ignoreDiscriminator: boolean): LightTour {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'operatorId': json['operatorId'],
        'active': json['active'],
        'meeting': !exists(json, 'meeting') ? undefined : PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsMeetingFromJSON(json['meeting']),
        'dropOff': !exists(json, 'dropOff') ? undefined : PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsDropOffFromJSON(json['dropOff']),
        'maxTravelersPerReservation': json['maxTravelersPerReservation'],
        'minTravelersPerTour': !exists(json, 'minTravelersPerTour') ? undefined : json['minTravelersPerTour'],
        'maxTravelersPerTour': json['maxTravelersPerTour'],
        'cutOffTime': !exists(json, 'cutOffTime') ? undefined : CutOffTimeFromJSON(json['cutOffTime']),
        'currency': CurrencyFromJSON(json['currency']),
        'travelersFields': !exists(json, 'travelersFields') ? undefined : TravelersFieldsFromJSON(json['travelersFields']),
        'productContent': LightProductContentFromJSON(json['productContent']),
    };
}

export function LightTourToJSON(value?: LightTour | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'operatorId': value.operatorId,
        'active': value.active,
        'meeting': PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsMeetingToJSON(value.meeting),
        'dropOff': PickTourOrIdOrOperatorIdOrActiveOrMeetingOrDropOffOrMaxTravelersPerReservationOrMinTravelersPerTourOrMaxTravelersPerTourOrCutOffTimeOrCurrencyOrTravelersFieldsDropOffToJSON(value.dropOff),
        'maxTravelersPerReservation': value.maxTravelersPerReservation,
        'minTravelersPerTour': value.minTravelersPerTour,
        'maxTravelersPerTour': value.maxTravelersPerTour,
        'cutOffTime': CutOffTimeToJSON(value.cutOffTime),
        'currency': CurrencyToJSON(value.currency),
        'travelersFields': TravelersFieldsToJSON(value.travelersFields),
        'productContent': LightProductContentToJSON(value.productContent),
    };
}

