import { createGlobalStyle, css } from "styled-components";
import { mediaQuery } from "../constants";
import { darken, lighten, tint } from "polished";

const global = css`
  /*rule to remove ResizeObserver error */
  iframe#webpack-dev-server-client-overlay {
    display: none !important;
  }
  /*rule to remove ResizeObserver error */

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 14px;
    color: #333333;
  }

  #root {
    height: calc(100vh - calc(100vh - 100%));
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    -moz-box-shadow: 0 0 0 1000px #fff inset;
    box-shadow: 0 0 0 1000px #fff inset;
  }

  .link-color {
    cursor: pointer;
    color: ${({ theme }) => darken(0.09, theme.colors.primary)};
  }

  .pointer {
    cursor: pointer;
  }

  strong {
    font-family: "UbuntuMedium", serif;
  }

  .data-entry-modal {
    background: #ededed;
    pointer-events: none;
  }

  .d-flex {
    display: flex;
  }

  h1,
  h2,
  h3 {
    color: #000;
  }
  a {
    color: ${({ theme }) => darken(0.09, theme.colors.primary)};
  }

  @media print {
    .hide-on-print {
      display: none;
    }
  }
`;

const antd = {
  button: css`
    .ant-btn-primary {
      color: ${({ theme }) => theme.colors.text_secondary};
      background: ${({ theme }) => theme.colors.primary};
      border-color: transparent;
      font-weight: 700;
      &:hover,
      &:focus {
        border-color: transparent;
        color: ${({ theme }) => theme.colors.text_secondary};
        background: ${({ theme }) => darken(0.07, theme.colors.primary)};
      }
    }

    .ant-btn-dangerous {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.error};
      border-color: transparent;
      font-weight: 500;
      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.error};
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => tint(0.25, theme.colors.error)};
      }
    }

    .ant-btn-default:hover,
    .ant-btn-default:focus {
      color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
    }
  `,
  tag: css`
    .ant-tag {
      margin-top: 0.04em;
      margin-bottom: 0.04rem;
    }
    .ant-tag-checkable-checked {
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.text};
    }
    .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
      color: ${({ theme }) => theme.colors.text};
    }
  `,
  tabs: css`
    .ant-tabs {
      .ant-tabs-nav {
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              .ant-tabs-tab-btn {
                text-shadow: 0 0 transparent;
                color: #1a1a1a;
              }
            }
          }
        }
      }
    }
    .ant-tabs-ink-bar {
      background: ${({ theme }) => theme.colors.primary};
    }
  `,
  pickerPanel: css`
    .ant-picker-panel {
      .ant-picker-date-panel {
        .ant-picker-body {
          .ant-picker-content {
            tbody {
              tr {
                td {
                  .ant-picker-cell-inner {
                    border-radius: ${({ theme }) =>
                      theme.border_radius.percentage_medium};

                    ::before {
                      border-radius: ${({ theme }) =>
                        theme.border_radius.percentage_medium};
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  uploadDragger: css`
    .ant-upload.ant-upload-drag {
      border-width: 0;
    }
  `,
  modal: css`
    .ant-modal-root {
      .ant-modal-wrap {
        .ant-modal {
          .ant-modal-content {
            .ant-modal-body {
              padding: 0.5rem;
              ${mediaQuery.minMobile} {
                padding: 1rem;
              }
            }
          }
        }
      }
    }
  `,
  notification: css`
    .copy-to-clipboard {
      border-radius: 1rem;
      background: rgb(44, 45, 48);
      color: rgb(220, 223, 227);
      @media print {
        display: none;
      }
      .ant-notification-notice-content {
        .ant-notification-notice-message {
          color: rgb(220, 223, 227);
        }
        .ant-notification-notice-description {
          color: rgb(220, 223, 227);
        }
      }
      .ant-notification-notice-close {
        color: rgb(220, 223, 227);
      }
    }

    .ant-notification-notice-with-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .notification-custom {
      display: grid;
      grid-template-columns: 1fr 13%;
      padding: 0;
      border-radius: 0.6rem;

      .ant-notification-notice-content {
        border-right: 1px solid ${({ theme }) => theme.colors.black};
        padding: 0.7rem;

        .ant-notification-notice-with-icon {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-template-rows: 1fr auto auto;
        }

        .ant-notification-notice-icon {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 0.7rem 0 0;
          grid-column: span 1;
          grid-row: span 3;
          width: auto;
          height: 100%;
          margin: auto;
          font-size: 2.3rem;
        }

        .ant-notification-notice-message {
          font-weight: 700;
          margin: 0;
        }

        .ant-notification-notice-description {
          margin-left: 0;
        }

        .ant-notification-notice-btn {
          margin: auto;
          margin-top: 0.6rem;
        }
      }

      .ant-notification-notice-close {
        position: relative;
        display: block;
        top: 0px;
        right: 0px;
        margin: auto;
        width: auto;
        height: auto;

        .ant-notification-close-icon {
          width: auto;
          height: auto;
        }
      }
    }
  `,
  select: css`
    .ant-select-dropdown {
      padding: 0;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #38e1a345;
    }
  `,
  checkBox: css`
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${({ theme }) => darken(0.09, theme.colors.primary)};
    }
    .ant-checkbox-checked::after {
      border-color: ${({ theme }) => darken(0.09, theme.colors.primary)};
    }
  `,
  datePicker: css`
    .ant-picker-header-view button:hover {
      &:hover {
        color: ${({ theme }) => darken(0.04, theme.colors.tertiary)};
      }
    }
    .ant-picker-today-btn {
      color: ${({ theme }) => theme.colors.tertiary};
      &:hover {
        color: ${({ theme }) => darken(0.04, theme.colors.tertiary)};
      }
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background: ${({ theme }) => theme.colors.primary};
    }

    .ant-picker-cell-in-view.ant-picker-cell-today
      .ant-picker-cell-inner::before {
      border-color: ${({ theme }) => theme.colors.primary};
    }

    .ant-picker-body,
    .ant-picker-header-view button,
    .ant-picker-input > input {
      text-transform: capitalize;
    }
  `,
  radio: css`
    .ant-radio-checked .ant-radio-inner {
      border-color: ${({ theme }) => theme.colors.primary};
      &::after {
        background-color: ${({ theme }) => darken(0.09, theme.colors.primary)};
      }
    }
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: ${({ theme }) => theme.colors.primary};
    }
  `,
  switch: css`
    .ant-switch {
      background-color: ${({ theme }) => theme.colors.error};
    }
    .ant-switch-checked {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  `,
  timePicker: css`
    .ant-picker-time-panel-column
      > li.ant-picker-time-panel-cell-selected
      .ant-picker-time-panel-cell-inner {
      background-color: #38e1a345;
    }
  `,
  typography: css`
    a.ant-typography,
    .ant-typography a {
      color: ${({ theme }) => theme.colors.secondary};
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
        color: ${({ theme }) => darken(0.05, theme.colors.secondary)};
      }
    }
  `,
  menu: css`
    .ant-menu-submenu-selected {
      color: ${({ theme }) => darken(0.09, theme.colors.primary)};
    }
    .ant-menu-item-selected {
      color: ${({ theme }) => darken(0.09, theme.colors.primary)};
    }
    .ant-menu-light .ant-menu-item:hover,
    .ant-menu-light .ant-menu-item-active,
    .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-light .ant-menu-submenu-active,
    .ant-menu-light .ant-menu-submenu-title:hover {
      color: ${({ theme }) => darken(0.09, theme.colors.primary)};
    }

    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
      border-right: 3px solid
        ${({ theme }) => darken(0.09, theme.colors.primary)};
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: ${({ theme }) => lighten(0.4, theme.colors.primary)};
    }
    .ant-menu-submenu:hover
      > .ant-menu-submenu-title
      > .ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover
      > .ant-menu-submenu-title
      > .ant-menu-submenu-arrow {
      color: ${({ theme }) => darken(0.09, theme.colors.primary)};
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background-color: ${({ theme }) => lighten(0.4, theme.colors.primary)};
    }
  `,
  previewGroup: css`
    .ant-image-preview-mask {
      background: rgb(0 0 0 / 88%);
    }

    .ant-image-preview-operations-wrapper {
      .ant-image-preview-operations {
        font-size: 1.5em;

        .ant-image-preview-operations-operation-rotateRight,
        .ant-image-preview-operations-operation-rotateLeft {
          display: none;
        }
      }
    }
    .ant-image-preview-switch-left,
    .ant-image-preview-switch-right {
      width: 54px;
      height: 54px;
      color: #fff;
      background: rgb(158 156 156 / 10%);
      &:hover {
        background: rgb(158 156 156 / 7%);
      }
    }
  `,
  popover: css`
    .ant-popover-content {
      .ant-popover-inner {
        padding: 10px;
        .ant-popover-title {
          padding: 0;
          border-bottom: none;
          text-align: center;
          h5 {
            line-height: 2;
            margin-bottom: 0;
          }
        }
        .ant-popover-inner-content {
          padding: 0 10px;
          text-align: center;
          font-weight: 600;
        }
      }
    }
  `,
};

const andtMobile = {
  modal: css`
    .am-modal-popup {
      top: 0;
    }
  `,
};

const utils = {
  disabled: css`
    .disabled {
      pointer-events: none;
      opacity: 0.5;
      color: gray;
    }
  `,
};

export const GlobalStyle = createGlobalStyle`
  ${global}
  ${andtMobile.modal}
  ${Object.values(antd).map((antdComponent) => antdComponent)}
  ${Object.values(utils).map((util) => util)}
`;
