/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BraintreeBody,
  CashCardlessNotification,
  DeleteDiscountBody,
  GetIsBeforeCutOff,
  GetReservation,
  OpenpayConfig,
  OptionAvailabilityPricing,
  PaymentLink,
  PostCancelReservationBody,
  PostChangeReservationBody,
  PostCheckoutResponse,
  PostCheckoutSessionLinkBody,
  PostDiscountCashBody,
  PostPartialReservationBody,
  PostPaymentIntentTokenBody,
  PostRefundBody,
  PostReservationBody,
  PostReservationPaymentLinkBody,
  PostReservationSendPaymentLinkBody,
  PostSetDropOffBody,
  PostSetPickupLocationBody,
  PostTransactionBody,
  PostTransactionResponse,
  PostTravelerBody,
  PutAdditionalInfoBody,
  PutContactBody,
  PutDiscountBody,
  PutReservationReceiptBody,
  PutSellerBody,
  PutTravelerBody,
  PutTravelersBody,
  Reservation,
  ReservationsSummary,
  StripeToken,
  TravelerPassport,
  TravelersList,
} from '../models';
import {
    BraintreeBodyFromJSON,
    BraintreeBodyToJSON,
    CashCardlessNotificationFromJSON,
    CashCardlessNotificationToJSON,
    DeleteDiscountBodyFromJSON,
    DeleteDiscountBodyToJSON,
    GetIsBeforeCutOffFromJSON,
    GetIsBeforeCutOffToJSON,
    GetReservationFromJSON,
    GetReservationToJSON,
    OpenpayConfigFromJSON,
    OpenpayConfigToJSON,
    OptionAvailabilityPricingFromJSON,
    OptionAvailabilityPricingToJSON,
    PaymentLinkFromJSON,
    PaymentLinkToJSON,
    PostCancelReservationBodyFromJSON,
    PostCancelReservationBodyToJSON,
    PostChangeReservationBodyFromJSON,
    PostChangeReservationBodyToJSON,
    PostCheckoutResponseFromJSON,
    PostCheckoutResponseToJSON,
    PostCheckoutSessionLinkBodyFromJSON,
    PostCheckoutSessionLinkBodyToJSON,
    PostDiscountCashBodyFromJSON,
    PostDiscountCashBodyToJSON,
    PostPartialReservationBodyFromJSON,
    PostPartialReservationBodyToJSON,
    PostPaymentIntentTokenBodyFromJSON,
    PostPaymentIntentTokenBodyToJSON,
    PostRefundBodyFromJSON,
    PostRefundBodyToJSON,
    PostReservationBodyFromJSON,
    PostReservationBodyToJSON,
    PostReservationPaymentLinkBodyFromJSON,
    PostReservationPaymentLinkBodyToJSON,
    PostReservationSendPaymentLinkBodyFromJSON,
    PostReservationSendPaymentLinkBodyToJSON,
    PostSetDropOffBodyFromJSON,
    PostSetDropOffBodyToJSON,
    PostSetPickupLocationBodyFromJSON,
    PostSetPickupLocationBodyToJSON,
    PostTransactionBodyFromJSON,
    PostTransactionBodyToJSON,
    PostTransactionResponseFromJSON,
    PostTransactionResponseToJSON,
    PostTravelerBodyFromJSON,
    PostTravelerBodyToJSON,
    PutAdditionalInfoBodyFromJSON,
    PutAdditionalInfoBodyToJSON,
    PutContactBodyFromJSON,
    PutContactBodyToJSON,
    PutDiscountBodyFromJSON,
    PutDiscountBodyToJSON,
    PutReservationReceiptBodyFromJSON,
    PutReservationReceiptBodyToJSON,
    PutSellerBodyFromJSON,
    PutSellerBodyToJSON,
    PutTravelerBodyFromJSON,
    PutTravelerBodyToJSON,
    PutTravelersBodyFromJSON,
    PutTravelersBodyToJSON,
    ReservationFromJSON,
    ReservationToJSON,
    ReservationsSummaryFromJSON,
    ReservationsSummaryToJSON,
    StripeTokenFromJSON,
    StripeTokenToJSON,
    TravelerPassportFromJSON,
    TravelerPassportToJSON,
    TravelersListFromJSON,
    TravelersListToJSON,
} from '../models';

export interface DeleteDiscountRequest {
    reservationId: string;
    deleteDiscountBody: DeleteDiscountBody;
}

export interface DeleteExtraRequest {
    reservationId: string;
    extraId: string;
}

export interface DeleteTravelersRequest {
    reservationId: string;
    ids: Array<string>;
    split?: string;
}

export interface GetBraintreeTokenRequest {
    reservationId: string;
}

export interface GetConfigRequest {
    reservationId: string;
}

export interface GetIsBeforeCutOffRequest {
    reservationId: string;
}

export interface GetReservationRequest {
    reservationId: string;
}

export interface GetReservationOptionAvailabilityPricingsRequest {
    reservationId: string;
    tourId: string;
    startDate: string;
    senior?: number;
    adult?: number;
    youth?: number;
    student?: number;
    child?: number;
    infant?: number;
    other1?: number;
    other2?: number;
    other3?: number;
    other4?: number;
    other5?: number;
}

export interface GetReservationsSummariesRequest {
    fromDate: string;
    toDate: string;
    operatorId: string;
}

export interface GetTravelersListsRequest {
    tourDate: string;
    tourId: string;
}

export interface PostCancelReservationRequest {
    reservationId: string;
    postCancelReservationBody: PostCancelReservationBody;
}

export interface PostChangeReservationRequest {
    reservationId: string;
    postChangeReservationBody: PostChangeReservationBody;
}

export interface PostDiscountCashRequest {
    reservationId: string;
    postDiscountCashBody: PostDiscountCashBody;
}

export interface PostPartialReservationRequest {
    postPartialReservationBody: PostPartialReservationBody;
    countryCode?: string;
    clientIP?: string;
}

export interface PostReactivateReservationRequest {
    reservationId: string;
}

export interface PostReservationRequest {
    postReservationBody: PostReservationBody;
    countryCode?: string;
    clientIP?: string;
}

export interface PostReservationBraintreeCheckoutRequest {
    reservationId: string;
    braintreeBody: BraintreeBody;
}

export interface PostReservationCashNotificationRequest {
    reservationId: string;
    cashCardlessNotification: CashCardlessNotification;
}

export interface PostReservationPayLaterRequest {
    reservationId: string;
}

export interface PostReservationPaymentLinkRequest {
    reservationId: string;
    postReservationPaymentLinkBody: PostReservationPaymentLinkBody;
    origin?: string;
}

export interface PostReservationRefundRequest {
    reservationId: string;
    postRefundBody: PostRefundBody;
}

export interface PostReservationSendPaymentLinkRequest {
    reservationId: string;
    postReservationSendPaymentLinkBody: PostReservationSendPaymentLinkBody;
}

export interface PostReservationStripeCheckoutSessionLinkRequest {
    reservationId: string;
    postCheckoutSessionLinkBody: PostCheckoutSessionLinkBody;
    origin?: string;
}

export interface PostReservationStripePaymentIntentTokenRequest {
    reservationId: string;
    postPaymentIntentTokenBody: PostPaymentIntentTokenBody;
}

export interface PostSetDropOffRequest {
    reservationId: string;
    postSetDropOffBody: PostSetDropOffBody;
}

export interface PostSetPickupLocationRequest {
    reservationId: string;
    postSetPickupLocationBody: PostSetPickupLocationBody;
}

export interface PostTransactionRequest {
    reservationId: string;
    postTransactionBody: PostTransactionBody;
}

export interface PostTravelerRequest {
    reservationId: string;
    postTravelerBody: PostTravelerBody;
}

export interface PutAdditionalInfoRequest {
    reservationId: string;
    putAdditionalInfoBody: PutAdditionalInfoBody;
}

export interface PutContactRequest {
    reservationId: string;
    putContactBody: PutContactBody;
}

export interface PutDiscountRequest {
    reservationId: string;
    putDiscountBody: PutDiscountBody;
}

export interface PutExtrasRequest {
    reservationId: string;
    extraIds: Array<string>;
    pending?: boolean;
}

export interface PutReservationOnHoldRequest {
    reservationId: string;
}

export interface PutReservationReceiptRequest {
    reservationId: string;
    putReservationReceiptBody: PutReservationReceiptBody;
}

export interface PutSellerRequest {
    reservationId: string;
    putSellerBody: PutSellerBody;
}

export interface PutTravelerRequest {
    reservationId: string;
    travelerId: string;
    putTravelerBody: PutTravelerBody;
}

export interface PutTravelersAndContactRequest {
    reservationId: string;
    putTravelersBody: PutTravelersBody;
}

export interface PutTravelersPassportsRequest {
    reservationId: string;
    travelerPassport: Array<TravelerPassport>;
}

/**
 * 
 */
export class ReservationsApi extends runtime.BaseAPI {

    /**
     */
    async deleteDiscountRaw(requestParameters: DeleteDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling deleteDiscount.');
        }

        if (requestParameters.deleteDiscountBody === null || requestParameters.deleteDiscountBody === undefined) {
            throw new runtime.RequiredError('deleteDiscountBody','Required parameter requestParameters.deleteDiscountBody was null or undefined when calling deleteDiscount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/discount`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteDiscountBodyToJSON(requestParameters.deleteDiscountBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDiscount(requestParameters: DeleteDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDiscountRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteExtraRaw(requestParameters: DeleteExtraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling deleteExtra.');
        }

        if (requestParameters.extraId === null || requestParameters.extraId === undefined) {
            throw new runtime.RequiredError('extraId','Required parameter requestParameters.extraId was null or undefined when calling deleteExtra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservations/{reservationId}/extras/{extraId}`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))).replace(`{${"extraId"}}`, encodeURIComponent(String(requestParameters.extraId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteExtra(requestParameters: DeleteExtraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteExtraRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteTravelersRaw(requestParameters: DeleteTravelersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling deleteTravelers.');
        }

        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling deleteTravelers.');
        }

        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.split !== undefined) {
            queryParameters['split'] = requestParameters.split;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservations/{reservationId}/travelers`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTravelers(requestParameters: DeleteTravelersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTravelersRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getBraintreeTokenRaw(requestParameters: GetBraintreeTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling getBraintreeToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservations/{reservationId}/payments/braintree/token`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getBraintreeToken(requestParameters: GetBraintreeTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getBraintreeTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getConfigRaw(requestParameters: GetConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpenpayConfig>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling getConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservations/{reservationId}/payments/openpay/config`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpenpayConfigFromJSON(jsonValue));
    }

    /**
     */
    async getConfig(requestParameters: GetConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpenpayConfig> {
        const response = await this.getConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getIsBeforeCutOffRaw(requestParameters: GetIsBeforeCutOffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetIsBeforeCutOff>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling getIsBeforeCutOff.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservations/{reservationId}/is-before-cut-off`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIsBeforeCutOffFromJSON(jsonValue));
    }

    /**
     */
    async getIsBeforeCutOff(requestParameters: GetIsBeforeCutOffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetIsBeforeCutOff> {
        const response = await this.getIsBeforeCutOffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReservationRaw(requestParameters: GetReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetReservation>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling getReservation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservations/{reservationId}`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetReservationFromJSON(jsonValue));
    }

    /**
     */
    async getReservation(requestParameters: GetReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetReservation> {
        const response = await this.getReservationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReservationOptionAvailabilityPricingsRaw(requestParameters: GetReservationOptionAvailabilityPricingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OptionAvailabilityPricing>>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling getReservationOptionAvailabilityPricings.');
        }

        if (requestParameters.tourId === null || requestParameters.tourId === undefined) {
            throw new runtime.RequiredError('tourId','Required parameter requestParameters.tourId was null or undefined when calling getReservationOptionAvailabilityPricings.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getReservationOptionAvailabilityPricings.');
        }

        const queryParameters: any = {};

        if (requestParameters.tourId !== undefined) {
            queryParameters['tourId'] = requestParameters.tourId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.senior !== undefined) {
            queryParameters['senior'] = requestParameters.senior;
        }

        if (requestParameters.adult !== undefined) {
            queryParameters['adult'] = requestParameters.adult;
        }

        if (requestParameters.youth !== undefined) {
            queryParameters['youth'] = requestParameters.youth;
        }

        if (requestParameters.student !== undefined) {
            queryParameters['student'] = requestParameters.student;
        }

        if (requestParameters.child !== undefined) {
            queryParameters['child'] = requestParameters.child;
        }

        if (requestParameters.infant !== undefined) {
            queryParameters['infant'] = requestParameters.infant;
        }

        if (requestParameters.other1 !== undefined) {
            queryParameters['other1'] = requestParameters.other1;
        }

        if (requestParameters.other2 !== undefined) {
            queryParameters['other2'] = requestParameters.other2;
        }

        if (requestParameters.other3 !== undefined) {
            queryParameters['other3'] = requestParameters.other3;
        }

        if (requestParameters.other4 !== undefined) {
            queryParameters['other4'] = requestParameters.other4;
        }

        if (requestParameters.other5 !== undefined) {
            queryParameters['other5'] = requestParameters.other5;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservations/{reservationId}/option-availability-pricings`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionAvailabilityPricingFromJSON));
    }

    /**
     */
    async getReservationOptionAvailabilityPricings(requestParameters: GetReservationOptionAvailabilityPricingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OptionAvailabilityPricing>> {
        const response = await this.getReservationOptionAvailabilityPricingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReservationsSummariesRaw(requestParameters: GetReservationsSummariesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReservationsSummary>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling getReservationsSummaries.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling getReservationsSummaries.');
        }

        if (requestParameters.operatorId === null || requestParameters.operatorId === undefined) {
            throw new runtime.RequiredError('operatorId','Required parameter requestParameters.operatorId was null or undefined when calling getReservationsSummaries.');
        }

        const queryParameters: any = {};

        if (requestParameters.operatorId !== undefined) {
            queryParameters['operatorId'] = requestParameters.operatorId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservations/summaries/{fromDate}/{toDate}`.replace(`{${"fromDate"}}`, encodeURIComponent(String(requestParameters.fromDate))).replace(`{${"toDate"}}`, encodeURIComponent(String(requestParameters.toDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReservationsSummaryFromJSON));
    }

    /**
     */
    async getReservationsSummaries(requestParameters: GetReservationsSummariesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReservationsSummary>> {
        const response = await this.getReservationsSummariesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTravelersListsRaw(requestParameters: GetTravelersListsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TravelersList>>> {
        if (requestParameters.tourDate === null || requestParameters.tourDate === undefined) {
            throw new runtime.RequiredError('tourDate','Required parameter requestParameters.tourDate was null or undefined when calling getTravelersLists.');
        }

        if (requestParameters.tourId === null || requestParameters.tourId === undefined) {
            throw new runtime.RequiredError('tourId','Required parameter requestParameters.tourId was null or undefined when calling getTravelersLists.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservations/travelers-lists/{tourDate}/{tourId}`.replace(`{${"tourDate"}}`, encodeURIComponent(String(requestParameters.tourDate))).replace(`{${"tourId"}}`, encodeURIComponent(String(requestParameters.tourId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TravelersListFromJSON));
    }

    /**
     */
    async getTravelersLists(requestParameters: GetTravelersListsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TravelersList>> {
        const response = await this.getTravelersListsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postCancelReservationRaw(requestParameters: PostCancelReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postCancelReservation.');
        }

        if (requestParameters.postCancelReservationBody === null || requestParameters.postCancelReservationBody === undefined) {
            throw new runtime.RequiredError('postCancelReservationBody','Required parameter requestParameters.postCancelReservationBody was null or undefined when calling postCancelReservation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/cancel`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCancelReservationBodyToJSON(requestParameters.postCancelReservationBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postCancelReservation(requestParameters: PostCancelReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postCancelReservationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postChangeReservationRaw(requestParameters: PostChangeReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postChangeReservation.');
        }

        if (requestParameters.postChangeReservationBody === null || requestParameters.postChangeReservationBody === undefined) {
            throw new runtime.RequiredError('postChangeReservationBody','Required parameter requestParameters.postChangeReservationBody was null or undefined when calling postChangeReservation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/change`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostChangeReservationBodyToJSON(requestParameters.postChangeReservationBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postChangeReservation(requestParameters: PostChangeReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postChangeReservationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postDiscountCashRaw(requestParameters: PostDiscountCashRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postDiscountCash.');
        }

        if (requestParameters.postDiscountCashBody === null || requestParameters.postDiscountCashBody === undefined) {
            throw new runtime.RequiredError('postDiscountCashBody','Required parameter requestParameters.postDiscountCashBody was null or undefined when calling postDiscountCash.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/discount-cash`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostDiscountCashBodyToJSON(requestParameters.postDiscountCashBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postDiscountCash(requestParameters: PostDiscountCashRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postDiscountCashRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postPartialReservationRaw(requestParameters: PostPartialReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.postPartialReservationBody === null || requestParameters.postPartialReservationBody === undefined) {
            throw new runtime.RequiredError('postPartialReservationBody','Required parameter requestParameters.postPartialReservationBody was null or undefined when calling postPartialReservation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.countryCode !== undefined && requestParameters.countryCode !== null) {
            headerParameters['countryCode'] = String(requestParameters.countryCode);
        }

        if (requestParameters.clientIP !== undefined && requestParameters.clientIP !== null) {
            headerParameters['clientIP'] = String(requestParameters.clientIP);
        }

        const response = await this.request({
            path: `/reservations/partial`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPartialReservationBodyToJSON(requestParameters.postPartialReservationBody),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async postPartialReservation(requestParameters: PostPartialReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.postPartialReservationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postReactivateReservationRaw(requestParameters: PostReactivateReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postReactivateReservation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservations/{reservationId}/reactivate`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postReactivateReservation(requestParameters: PostReactivateReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postReactivateReservationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postReservationRaw(requestParameters: PostReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Reservation>> {
        if (requestParameters.postReservationBody === null || requestParameters.postReservationBody === undefined) {
            throw new runtime.RequiredError('postReservationBody','Required parameter requestParameters.postReservationBody was null or undefined when calling postReservation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.countryCode !== undefined && requestParameters.countryCode !== null) {
            headerParameters['countryCode'] = String(requestParameters.countryCode);
        }

        if (requestParameters.clientIP !== undefined && requestParameters.clientIP !== null) {
            headerParameters['clientIP'] = String(requestParameters.clientIP);
        }

        const response = await this.request({
            path: `/reservations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostReservationBodyToJSON(requestParameters.postReservationBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationFromJSON(jsonValue));
    }

    /**
     */
    async postReservation(requestParameters: PostReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Reservation> {
        const response = await this.postReservationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postReservationBraintreeCheckoutRaw(requestParameters: PostReservationBraintreeCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostCheckoutResponse>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postReservationBraintreeCheckout.');
        }

        if (requestParameters.braintreeBody === null || requestParameters.braintreeBody === undefined) {
            throw new runtime.RequiredError('braintreeBody','Required parameter requestParameters.braintreeBody was null or undefined when calling postReservationBraintreeCheckout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/payments/braintree/checkout`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BraintreeBodyToJSON(requestParameters.braintreeBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostCheckoutResponseFromJSON(jsonValue));
    }

    /**
     */
    async postReservationBraintreeCheckout(requestParameters: PostReservationBraintreeCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostCheckoutResponse> {
        const response = await this.postReservationBraintreeCheckoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postReservationCashNotificationRaw(requestParameters: PostReservationCashNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postReservationCashNotification.');
        }

        if (requestParameters.cashCardlessNotification === null || requestParameters.cashCardlessNotification === undefined) {
            throw new runtime.RequiredError('cashCardlessNotification','Required parameter requestParameters.cashCardlessNotification was null or undefined when calling postReservationCashNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/payments/cash`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CashCardlessNotificationToJSON(requestParameters.cashCardlessNotification),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postReservationCashNotification(requestParameters: PostReservationCashNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postReservationCashNotificationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postReservationPayLaterRaw(requestParameters: PostReservationPayLaterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postReservationPayLater.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservations/{reservationId}/pay-later`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postReservationPayLater(requestParameters: PostReservationPayLaterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postReservationPayLaterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postReservationPaymentLinkRaw(requestParameters: PostReservationPaymentLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentLink>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postReservationPaymentLink.');
        }

        if (requestParameters.postReservationPaymentLinkBody === null || requestParameters.postReservationPaymentLinkBody === undefined) {
            throw new runtime.RequiredError('postReservationPaymentLinkBody','Required parameter requestParameters.postReservationPaymentLinkBody was null or undefined when calling postReservationPaymentLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.origin !== undefined && requestParameters.origin !== null) {
            headerParameters['origin'] = String(requestParameters.origin);
        }

        const response = await this.request({
            path: `/reservations/{reservationId}/payment-links`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostReservationPaymentLinkBodyToJSON(requestParameters.postReservationPaymentLinkBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentLinkFromJSON(jsonValue));
    }

    /**
     */
    async postReservationPaymentLink(requestParameters: PostReservationPaymentLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentLink> {
        const response = await this.postReservationPaymentLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postReservationRefundRaw(requestParameters: PostReservationRefundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postReservationRefund.');
        }

        if (requestParameters.postRefundBody === null || requestParameters.postRefundBody === undefined) {
            throw new runtime.RequiredError('postRefundBody','Required parameter requestParameters.postRefundBody was null or undefined when calling postReservationRefund.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/refund`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostRefundBodyToJSON(requestParameters.postRefundBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postReservationRefund(requestParameters: PostReservationRefundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postReservationRefundRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postReservationSendPaymentLinkRaw(requestParameters: PostReservationSendPaymentLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postReservationSendPaymentLink.');
        }

        if (requestParameters.postReservationSendPaymentLinkBody === null || requestParameters.postReservationSendPaymentLinkBody === undefined) {
            throw new runtime.RequiredError('postReservationSendPaymentLinkBody','Required parameter requestParameters.postReservationSendPaymentLinkBody was null or undefined when calling postReservationSendPaymentLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/send-payment-link`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostReservationSendPaymentLinkBodyToJSON(requestParameters.postReservationSendPaymentLinkBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postReservationSendPaymentLink(requestParameters: PostReservationSendPaymentLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postReservationSendPaymentLinkRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postReservationStripeCheckoutSessionLinkRaw(requestParameters: PostReservationStripeCheckoutSessionLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentLink>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postReservationStripeCheckoutSessionLink.');
        }

        if (requestParameters.postCheckoutSessionLinkBody === null || requestParameters.postCheckoutSessionLinkBody === undefined) {
            throw new runtime.RequiredError('postCheckoutSessionLinkBody','Required parameter requestParameters.postCheckoutSessionLinkBody was null or undefined when calling postReservationStripeCheckoutSessionLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.origin !== undefined && requestParameters.origin !== null) {
            headerParameters['origin'] = String(requestParameters.origin);
        }

        const response = await this.request({
            path: `/reservations/{reservationId}/payments/stripe/checkout-session/link`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCheckoutSessionLinkBodyToJSON(requestParameters.postCheckoutSessionLinkBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentLinkFromJSON(jsonValue));
    }

    /**
     */
    async postReservationStripeCheckoutSessionLink(requestParameters: PostReservationStripeCheckoutSessionLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentLink> {
        const response = await this.postReservationStripeCheckoutSessionLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postReservationStripePaymentIntentTokenRaw(requestParameters: PostReservationStripePaymentIntentTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StripeToken>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postReservationStripePaymentIntentToken.');
        }

        if (requestParameters.postPaymentIntentTokenBody === null || requestParameters.postPaymentIntentTokenBody === undefined) {
            throw new runtime.RequiredError('postPaymentIntentTokenBody','Required parameter requestParameters.postPaymentIntentTokenBody was null or undefined when calling postReservationStripePaymentIntentToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/payments/stripe/payment-intent/token`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPaymentIntentTokenBodyToJSON(requestParameters.postPaymentIntentTokenBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StripeTokenFromJSON(jsonValue));
    }

    /**
     */
    async postReservationStripePaymentIntentToken(requestParameters: PostReservationStripePaymentIntentTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StripeToken> {
        const response = await this.postReservationStripePaymentIntentTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postSetDropOffRaw(requestParameters: PostSetDropOffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postSetDropOff.');
        }

        if (requestParameters.postSetDropOffBody === null || requestParameters.postSetDropOffBody === undefined) {
            throw new runtime.RequiredError('postSetDropOffBody','Required parameter requestParameters.postSetDropOffBody was null or undefined when calling postSetDropOff.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/set-drop-off`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostSetDropOffBodyToJSON(requestParameters.postSetDropOffBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postSetDropOff(requestParameters: PostSetDropOffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSetDropOffRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postSetPickupLocationRaw(requestParameters: PostSetPickupLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postSetPickupLocation.');
        }

        if (requestParameters.postSetPickupLocationBody === null || requestParameters.postSetPickupLocationBody === undefined) {
            throw new runtime.RequiredError('postSetPickupLocationBody','Required parameter requestParameters.postSetPickupLocationBody was null or undefined when calling postSetPickupLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/set-pickup-location`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostSetPickupLocationBodyToJSON(requestParameters.postSetPickupLocationBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postSetPickupLocation(requestParameters: PostSetPickupLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSetPickupLocationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postTransactionRaw(requestParameters: PostTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostTransactionResponse>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postTransaction.');
        }

        if (requestParameters.postTransactionBody === null || requestParameters.postTransactionBody === undefined) {
            throw new runtime.RequiredError('postTransactionBody','Required parameter requestParameters.postTransactionBody was null or undefined when calling postTransaction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/payments/openpay/transaction`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostTransactionBodyToJSON(requestParameters.postTransactionBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostTransactionResponseFromJSON(jsonValue));
    }

    /**
     */
    async postTransaction(requestParameters: PostTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostTransactionResponse> {
        const response = await this.postTransactionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postTravelerRaw(requestParameters: PostTravelerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling postTraveler.');
        }

        if (requestParameters.postTravelerBody === null || requestParameters.postTravelerBody === undefined) {
            throw new runtime.RequiredError('postTravelerBody','Required parameter requestParameters.postTravelerBody was null or undefined when calling postTraveler.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/travelers`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostTravelerBodyToJSON(requestParameters.postTravelerBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postTraveler(requestParameters: PostTravelerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postTravelerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putAdditionalInfoRaw(requestParameters: PutAdditionalInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling putAdditionalInfo.');
        }

        if (requestParameters.putAdditionalInfoBody === null || requestParameters.putAdditionalInfoBody === undefined) {
            throw new runtime.RequiredError('putAdditionalInfoBody','Required parameter requestParameters.putAdditionalInfoBody was null or undefined when calling putAdditionalInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/additional-info`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutAdditionalInfoBodyToJSON(requestParameters.putAdditionalInfoBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putAdditionalInfo(requestParameters: PutAdditionalInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putAdditionalInfoRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putContactRaw(requestParameters: PutContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling putContact.');
        }

        if (requestParameters.putContactBody === null || requestParameters.putContactBody === undefined) {
            throw new runtime.RequiredError('putContactBody','Required parameter requestParameters.putContactBody was null or undefined when calling putContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/contact`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutContactBodyToJSON(requestParameters.putContactBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putContact(requestParameters: PutContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putContactRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putDiscountRaw(requestParameters: PutDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling putDiscount.');
        }

        if (requestParameters.putDiscountBody === null || requestParameters.putDiscountBody === undefined) {
            throw new runtime.RequiredError('putDiscountBody','Required parameter requestParameters.putDiscountBody was null or undefined when calling putDiscount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/discount`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutDiscountBodyToJSON(requestParameters.putDiscountBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putDiscount(requestParameters: PutDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putDiscountRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putExtrasRaw(requestParameters: PutExtrasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling putExtras.');
        }

        if (requestParameters.extraIds === null || requestParameters.extraIds === undefined) {
            throw new runtime.RequiredError('extraIds','Required parameter requestParameters.extraIds was null or undefined when calling putExtras.');
        }

        const queryParameters: any = {};

        if (requestParameters.extraIds) {
            queryParameters['extraIds'] = requestParameters.extraIds;
        }

        if (requestParameters.pending !== undefined) {
            queryParameters['pending'] = requestParameters.pending;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservations/{reservationId}/extras`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putExtras(requestParameters: PutExtrasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putExtrasRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putReservationOnHoldRaw(requestParameters: PutReservationOnHoldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling putReservationOnHold.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservations/{reservationId}/on-hold`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putReservationOnHold(requestParameters: PutReservationOnHoldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putReservationOnHoldRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putReservationReceiptRaw(requestParameters: PutReservationReceiptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling putReservationReceipt.');
        }

        if (requestParameters.putReservationReceiptBody === null || requestParameters.putReservationReceiptBody === undefined) {
            throw new runtime.RequiredError('putReservationReceiptBody','Required parameter requestParameters.putReservationReceiptBody was null or undefined when calling putReservationReceipt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/receipt`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutReservationReceiptBodyToJSON(requestParameters.putReservationReceiptBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putReservationReceipt(requestParameters: PutReservationReceiptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putReservationReceiptRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putSellerRaw(requestParameters: PutSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling putSeller.');
        }

        if (requestParameters.putSellerBody === null || requestParameters.putSellerBody === undefined) {
            throw new runtime.RequiredError('putSellerBody','Required parameter requestParameters.putSellerBody was null or undefined when calling putSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/seller`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutSellerBodyToJSON(requestParameters.putSellerBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putSeller(requestParameters: PutSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putSellerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putTravelerRaw(requestParameters: PutTravelerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling putTraveler.');
        }

        if (requestParameters.travelerId === null || requestParameters.travelerId === undefined) {
            throw new runtime.RequiredError('travelerId','Required parameter requestParameters.travelerId was null or undefined when calling putTraveler.');
        }

        if (requestParameters.putTravelerBody === null || requestParameters.putTravelerBody === undefined) {
            throw new runtime.RequiredError('putTravelerBody','Required parameter requestParameters.putTravelerBody was null or undefined when calling putTraveler.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/travelers/{travelerId}`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))).replace(`{${"travelerId"}}`, encodeURIComponent(String(requestParameters.travelerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutTravelerBodyToJSON(requestParameters.putTravelerBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putTraveler(requestParameters: PutTravelerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putTravelerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putTravelersAndContactRaw(requestParameters: PutTravelersAndContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling putTravelersAndContact.');
        }

        if (requestParameters.putTravelersBody === null || requestParameters.putTravelersBody === undefined) {
            throw new runtime.RequiredError('putTravelersBody','Required parameter requestParameters.putTravelersBody was null or undefined when calling putTravelersAndContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/travelers`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutTravelersBodyToJSON(requestParameters.putTravelersBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putTravelersAndContact(requestParameters: PutTravelersAndContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putTravelersAndContactRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putTravelersPassportsRaw(requestParameters: PutTravelersPassportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationId === null || requestParameters.reservationId === undefined) {
            throw new runtime.RequiredError('reservationId','Required parameter requestParameters.reservationId was null or undefined when calling putTravelersPassports.');
        }

        if (requestParameters.travelerPassport === null || requestParameters.travelerPassport === undefined) {
            throw new runtime.RequiredError('travelerPassport','Required parameter requestParameters.travelerPassport was null or undefined when calling putTravelersPassports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservations/{reservationId}/travelers-passports`.replace(`{${"reservationId"}}`, encodeURIComponent(String(requestParameters.reservationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.travelerPassport.map(TravelerPassportToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putTravelersPassports(requestParameters: PutTravelersPassportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putTravelersPassportsRaw(requestParameters, initOverrides);
    }

}
