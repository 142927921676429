/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { Duration } from './Duration';
import {
    DurationFromJSON,
    DurationFromJSONTyped,
    DurationToJSON,
} from './Duration';

/**
 * 
 * @export
 * @interface TourSearchResult
 */
export interface TourSearchResult {
    /**
     * 
     * @type {string}
     * @memberof TourSearchResult
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof TourSearchResult
     */
    countryId?: string;
    /**
     * 
     * @type {Currency}
     * @memberof TourSearchResult
     */
    currency: Currency;
    /**
     * 
     * @type {string}
     * @memberof TourSearchResult
     */
    description?: string;
    /**
     * 
     * @type {Duration}
     * @memberof TourSearchResult
     */
    duration?: Duration;
    /**
     * 
     * @type {string}
     * @memberof TourSearchResult
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TourSearchResult
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof TourSearchResult
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TourSearchResult
     */
    price?: string;
    /**
     * 
     * @type {number}
     * @memberof TourSearchResult
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof TourSearchResult
     */
    reviewsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof TourSearchResult
     */
    seoPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TourSearchResult
     */
    categories: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TourSearchResult
     */
    label?: string;
}

/**
 * Check if a given object implements the TourSearchResult interface.
 */
export function instanceOfTourSearchResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "categories" in value;

    return isInstance;
}

export function TourSearchResultFromJSON(json: any): TourSearchResult {
    return TourSearchResultFromJSONTyped(json, false);
}

export function TourSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TourSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cityName': !exists(json, 'cityName') ? undefined : json['cityName'],
        'countryId': !exists(json, 'countryId') ? undefined : json['countryId'],
        'currency': CurrencyFromJSON(json['currency']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'duration': !exists(json, 'duration') ? undefined : DurationFromJSON(json['duration']),
        'id': json['id'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'name': json['name'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'reviewsCount': !exists(json, 'reviewsCount') ? undefined : json['reviewsCount'],
        'seoPath': !exists(json, 'seoPath') ? undefined : json['seoPath'],
        'categories': json['categories'],
        'label': !exists(json, 'label') ? undefined : json['label'],
    };
}

export function TourSearchResultToJSON(value?: TourSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cityName': value.cityName,
        'countryId': value.countryId,
        'currency': CurrencyToJSON(value.currency),
        'description': value.description,
        'duration': DurationToJSON(value.duration),
        'id': value.id,
        'image': value.image,
        'name': value.name,
        'price': value.price,
        'rating': value.rating,
        'reviewsCount': value.reviewsCount,
        'seoPath': value.seoPath,
        'categories': value.categories,
        'label': value.label,
    };
}

