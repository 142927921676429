/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StripeToken
 */
export interface StripeToken {
    /**
     * 
     * @type {string}
     * @memberof StripeToken
     */
    clientSecret: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeToken
     */
    publishableKey: string;
    /**
     * 
     * @type {string}
     * @memberof StripeToken
     */
    paymentIntentId: string;
}

/**
 * Check if a given object implements the StripeToken interface.
 */
export function instanceOfStripeToken(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientSecret" in value;
    isInstance = isInstance && "publishableKey" in value;
    isInstance = isInstance && "paymentIntentId" in value;

    return isInstance;
}

export function StripeTokenFromJSON(json: any): StripeToken {
    return StripeTokenFromJSONTyped(json, false);
}

export function StripeTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientSecret': json['clientSecret'],
        'publishableKey': json['publishableKey'],
        'paymentIntentId': json['paymentIntentId'],
    };
}

export function StripeTokenToJSON(value?: StripeToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientSecret': value.clientSecret,
        'publishableKey': value.publishableKey,
        'paymentIntentId': value.paymentIntentId,
    };
}

