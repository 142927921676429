/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A Timestamp represents a point in time independent of any time zone or
 * calendar, represented as seconds and fractions of seconds at nanosecond
 * resolution in UTC Epoch time. It is encoded using the Proleptic Gregorian
 * Calendar which extends the Gregorian calendar backwards to year one. It is
 * encoded assuming all minutes are 60 seconds long, i.e. leap seconds are
 * "smeared" so that no leap second table is needed for interpretation. Range
 * is from 0001-01-01T00:00:00Z to 9999-12-31T23:59:59.999999999Z.
 * @export
 * @interface FirebaseFirestoreTimestamp
 */
export interface FirebaseFirestoreTimestamp {
    /**
     * The number of seconds of UTC time since Unix epoch 1970-01-01T00:00:00Z.
     * @type {number}
     * @memberof FirebaseFirestoreTimestamp
     */
    seconds: number;
    /**
     * The non-negative fractions of a second at nanosecond resolution.
     * @type {number}
     * @memberof FirebaseFirestoreTimestamp
     */
    nanoseconds: number;
}

/**
 * Check if a given object implements the FirebaseFirestoreTimestamp interface.
 */
export function instanceOfFirebaseFirestoreTimestamp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "seconds" in value;
    isInstance = isInstance && "nanoseconds" in value;

    return isInstance;
}

export function FirebaseFirestoreTimestampFromJSON(json: any): FirebaseFirestoreTimestamp {
    return FirebaseFirestoreTimestampFromJSONTyped(json, false);
}

export function FirebaseFirestoreTimestampFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirebaseFirestoreTimestamp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seconds': json['seconds'],
        'nanoseconds': json['nanoseconds'],
    };
}

export function FirebaseFirestoreTimestampToJSON(value?: FirebaseFirestoreTimestamp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seconds': value.seconds,
        'nanoseconds': value.nanoseconds,
    };
}

