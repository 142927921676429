import React, { createContext, useContext, useEffect, useState } from "react";
import { useCollectionData } from "../hooks/firebase";
import { notification, Spinner } from "../components";
import { orderBy } from "lodash";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { useOpenApi } from "../api";
import { CountriesApi, CountryISO } from "../openapi";

interface Context {
  operators: Operator[];
  countries: CountryISO[];
}

const GlobalDataContext = createContext<Context>({
  operators: [],
  countries: [],
});

interface Props {
  children: JSX.Element;
}

export const GlobalDataProvider = ({ children }: Props): JSX.Element => {
  const [firebaseAuthUserId, setFirebaseAuthUserId] = useState<string>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) =>
      setFirebaseAuthUserId(authUser?.uid)
    );

    return () => unsubscribe();
  }, []);

  const {
    data: operators,
    loading: operatorsLoading,
    error: operatorsError,
  } = useCollectionData<Operator>(
    "onSnapshot",
    "operators",
    [],
    [firebaseAuthUserId],
    !firebaseAuthUserId
  );

  const { data: countries = [] } = useOpenApi(CountriesApi, "getCountries", {
    initialParams: [],
  });

  useEffect(() => {
    operatorsError && notification({ type: "error" });
  }, [operatorsError]);

  if (operatorsLoading) return <Spinner fullscreen />;

  return (
    <GlobalDataContext.Provider
      value={{
        operators: orderBy(operators, (operator) => operator.name, "asc"),
        countries: orderBy(countries, ["name"], ["asc"]),
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
};

export const useGlobalData = (): Context => useContext(GlobalDataContext);
