import React, { useEffect, useState } from "react";
import { PDF } from "./sheets";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useDocumentData } from "../hooks/firebase";
import { useParams } from "react-router";
import { DATETIME_12H_FORMAT, DEFAULT_DATE_FORMAT } from "../constants";
import { useLanguage } from "../providers";
import { useI18n } from "../hooks";
import { Spinner } from "./Spinner";
import { calculateTourDates, TourDates } from "../utils/tours";

export const ReservationPrintIntegration: React.FC = () => {
  const [tourDates, setTourDates] = useState<TourDates>();
  const { currentLanguageCode } = useLanguage();
  const { reservationId } = useParams();
  const translate = useI18n();

  const [reservation, reservationLoading] = useDocumentData<Reservation>(
    "reservations",
    reservationId
  );

  const [operator, operatorLoading] = useDocumentData<Operator>(
    "operators",
    reservation?.tour.operatorId
  );

  const [tour, tourLoading] = useDocumentData<Tour>(
    "tours",
    reservation?.tour.id
  );

  useEffect(() => {
    if (reservation && operator) {
      const tourDates = calculateTourDates(reservation, operator);
      setTourDates(tourDates);
    }
  }, [reservation, operator]);

  if (reservationLoading || operatorLoading || tourLoading) {
    return <Spinner fullscreen />;
  }

  return (
    <PDF>
      <Helmet>
        <title>
          {`${tourDates?.startDate.format(DEFAULT_DATE_FORMAT)} 
          ${tour?.productContent.productSetup.name[currentLanguageCode]}`}
        </title>
      </Helmet>
      <PrintContainer>
        <Header backgroundColor={operator?.design?.headerBackgroundColor}>
          <OperatorDetails>
            {operator?.logo ? (
              <img
                className="operator-logo"
                src={operator.logo.url}
                alt={operator?.name}
              />
            ) : (
              <h4 className="operator-name">{operator?.name}</h4>
            )}
          </OperatorDetails>
          <TicketDetails>
            <h3 className="ticket">{translate("general.ticket")}</h3>
            <p className="purchase-number">#{reservation?.purchaseNumber}</p>
          </TicketDetails>
        </Header>
        <Section>
          {operator?.logo && (
            <h4 className="operator-name">{operator?.name}</h4>
          )}
          <p className="tour-name">
            {
              reservation?.tour.productContent?.productSetup.name[
                currentLanguageCode
              ]
            }
          </p>
          <p>
            <span className="label">{translate("general.departure")}: </span>
            {tourDates?.startDate.format(DATETIME_12H_FORMAT)}
          </p>
          <p>
            <span className="label">{translate("general.arrival")}: </span>
            {tourDates?.endDate.format(DATETIME_12H_FORMAT)}
          </p>
          {tour?.meeting?.type === "meeting_point" && (
            <>
              <p>
                <span className="label">
                  {translate("general.meeting-point")}:{" "}
                </span>
                {tour.meeting.point[currentLanguageCode]}
              </p>
              <p>
                <span className="label">{translate("general.notice")}: </span>
                {translate("general.notice-description-1")}
                <span className="label">
                  {tour.meeting.minutesBeforeMeeting}{" "}
                  {translate("countries.minutes").toLowerCase()}
                </span>
                {translate("general.notice-description-2")}
              </p>
            </>
          )}
        </Section>
        {reservation?.extras && reservation?.extras?.length > 0 && (
          <Section>
            <h2>{translate("tour.extras.title")}</h2>
            {reservation?.extras.map((extra, index) => (
              <p key={`${extra.name}-${index}`}>
                - {extra.name[currentLanguageCode]}
              </p>
            ))}
          </Section>
        )}
        <Section>
          <h2>{translate("general.travelers")}</h2>
          <ul className="travelers-list">
            {reservation?.travelers.map((traveler, index) => (
              <li
                key={index}
              >{`${traveler.firstName} ${traveler.lastName}`}</li>
            ))}
          </ul>
        </Section>
        <Section>
          <h2>{translate("tour.product-content.cancellation-policy")}</h2>
          <p>{tour?.productContent?.cancelPolicy?.[currentLanguageCode]}</p>
        </Section>
      </PrintContainer>
    </PDF>
  );
};

const PrintContainer = styled.div`
  width: 210mm;
  margin: 16px auto;
  font-size: 1rem;
  background-color: #fff;

  p {
    margin-bottom: 0.5rem;
  }

  h2 {
    margin-bottom: 0.2rem;
  }

  .tour-name {
    font-size: 1.3rem;
    font-weight: 600;
  }

  @media print {
    @page {
      size: A4 portrait;
      margin: 10mm;
    }
    height: auto;
    min-height: initial;
    margin: 0;
    padding: 10mm;
  }
`;

const TicketDetails = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 5px 15px;

  .ticket {
    line-height: 1.2;
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin: 0;
  }

  .purchase-number {
    margin: 0;
    font-size: 1rem;
    text-align: right;
    font-weight: 600;
  }
`;

const OperatorDetails = styled.div`
  display: flex;
  flex-direction: column;

  & .operator-logo {
    width: 140px;
    height: 140px;
    object-fit: contain;
    aspect-ratio: 1/1;
  }

  & .operator-name {
    margin: 0;
    line-height: 1.8;
    font-size: 2rem;
    font-weight: 700;
    color: #333;
    max-width: 740px;
  }
`;

const Header = styled.div<{ backgroundColor?: string }>`
  height: 140px;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.backgroundColor || "#fff"};

  @media print {
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }
`;

const Section = styled.section`
  padding: 10px 30px;

  .label {
    font-weight: 600;
  }

  .travelers-list {
    list-style: decimal;
    padding-left: 1rem;
  }

  .operator-name {
    margin: 0;
    line-height: 1.8;
    font-size: 2rem;
    font-weight: 700;
    color: #333;
  }

  @media print {
    page-break-inside: avoid;
    margin: 5mm 0;
  }
`;
