/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Action = {
    Reservation: 'confirm_reservation',
    PendingExtras: 'confirm_pending_extras'
} as const;
export type Action = typeof Action[keyof typeof Action];


export function ActionFromJSON(json: any): Action {
    return ActionFromJSONTyped(json, false);
}

export function ActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Action {
    return json as Action;
}

export function ActionToJSON(value?: Action | null): any {
    return value as any;
}

