import React, { memo } from "react";
import { useLazyComponent } from "../../../hooks";

export interface PublicHeaderProps {
  translate: Translate;
  currentLanguageCode: LanguageCode;
  onChangeLanguage: (languageCode: LanguageCode) => void;
  onSetIsActiveMenu: () => void;
  isMobile: boolean;
  authUser: AuthUser | null;
  utm: Utm | null;
  currentOffice: Office | null;
  onClickLogout: () => Promise<void>;
  onClickLogo: (path: string) => void;
  onClickLogin: () => void;
  referrer: string;
}

interface LazyComponentProps {
  domain: Domain;
  subdomain: Subdomain;
}

const PublicHeader = ({
  domain,
  subdomain,
  ...props
}: PublicHeaderProps & LazyComponentProps): React.ReactElement => {
  const LazyComponent = useLazyComponent<PublicHeaderProps>(
    () => import(`./${domain}/${subdomain}/Public.Header`),
    "empty",
    [domain, subdomain]
  );

  return <LazyComponent {...props} />;
};

export default memo(PublicHeader);
