/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentAccountId = {
    Stripe: 'stripe',
    Braintree: 'braintree',
    OpenpayWebuild: 'openpayWebuild',
    OpenpayAirportExpressLima: 'openpayAirportExpressLima'
} as const;
export type PaymentAccountId = typeof PaymentAccountId[keyof typeof PaymentAccountId];


export function PaymentAccountIdFromJSON(json: any): PaymentAccountId {
    return PaymentAccountIdFromJSONTyped(json, false);
}

export function PaymentAccountIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentAccountId {
    return json as PaymentAccountId;
}

export function PaymentAccountIdToJSON(value?: PaymentAccountId | null): any {
    return value as any;
}

