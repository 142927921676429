import { languages } from "../data-list";

export const buildDefaultSeoPath = (
  countryName: string,
  cityName: string,
  tourName: string,
  languageCode: LanguageCode,
  tourId: string
): string => {
  const editablePath = [countryName, cityName, tourName]
    .map(
      (name) =>
        name
          .trim()
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .replaceAll(/ /g, "-") // replace space with hyphen
          //eslint-disable-next-line
          .replace(/[^a-z0-9\-]+/g, "") // only alphanumeric, slash and hyphen
          .replace(/(\/{2,}|-{2,})/g, (match) => match.charAt(0)) // without slash or hyphen together
    )
    .join("/");

  return buildSeoPath(editablePath, languageCode, "t", tourId);
};

export const buildSeoPath = (
  editablePath: string,
  languageCode: LanguageCode,
  pageId: PageId,
  id: string | null
) => {
  let seoPath = `/${languageCode}/${pageId}/${editablePath}`;

  if (id) seoPath = `${seoPath}/_${id}`;

  return seoPath;
};

interface SeoPathParts {
  prefix: string;
  pageId: PageId;
  languageCode: LanguageCode;
  editablePath: string;
  suffix: string | undefined;
  id: string | undefined;
}

export const getSeoPathParts = (seoPath: string): SeoPathParts | null => {
  const languageCodes = languages.map((language) => language.code);

  const languageCodePattern = `${languageCodes.join("|")}`;
  const editablePathPattern = "(?:[^/]+/)*/?[^/_]+";
  const pageIdPattern = "[a-z]";
  const idPattern = "/_([^/]+)";

  const seoPathRegex = new RegExp(
    `^(/(${languageCodePattern})/(${pageIdPattern})/)(${editablePathPattern})(?:(${idPattern}))?$`
  );

  const match = seoPath.match(seoPathRegex);

  if (match) {
    return {
      prefix: match[1],
      pageId: match[3] as PageId,
      languageCode: match[2] as LanguageCode,
      editablePath: match[4],
      suffix: match[5] || undefined,
      id: match[6] || undefined,
    };
  }

  return null;
};
