/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Action } from './Action';
import {
    ActionFromJSON,
    ActionFromJSONTyped,
    ActionToJSON,
} from './Action';
import type { FirebaseFirestoreTimestamp } from './FirebaseFirestoreTimestamp';
import {
    FirebaseFirestoreTimestampFromJSON,
    FirebaseFirestoreTimestampFromJSONTyped,
    FirebaseFirestoreTimestampToJSON,
} from './FirebaseFirestoreTimestamp';
import type { PaymentAccountId } from './PaymentAccountId';
import {
    PaymentAccountIdFromJSON,
    PaymentAccountIdFromJSONTyped,
    PaymentAccountIdToJSON,
} from './PaymentAccountId';

/**
 * 
 * @export
 * @interface PaymentLink
 */
export interface PaymentLink {
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    reservationId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    currencyCode: string;
    /**
     * 
     * @type {PaymentAccountId}
     * @memberof PaymentLink
     */
    paymentAccountId: PaymentAccountId;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentLink
     */
    url: string;
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof PaymentLink
     */
    expireAt: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof PaymentLink
     */
    paidAt?: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {Action}
     * @memberof PaymentLink
     */
    action: Action | null;
}

/**
 * Check if a given object implements the PaymentLink interface.
 */
export function instanceOfPaymentLink(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "reservationId" in value;
    isInstance = isInstance && "currencyCode" in value;
    isInstance = isInstance && "paymentAccountId" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "expireAt" in value;
    isInstance = isInstance && "action" in value;

    return isInstance;
}

export function PaymentLinkFromJSON(json: any): PaymentLink {
    return PaymentLinkFromJSONTyped(json, false);
}

export function PaymentLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reservationId': json['reservationId'],
        'currencyCode': json['currencyCode'],
        'paymentAccountId': PaymentAccountIdFromJSON(json['paymentAccountId']),
        'amount': json['amount'],
        'url': json['url'],
        'expireAt': FirebaseFirestoreTimestampFromJSON(json['expireAt']),
        'paidAt': !exists(json, 'paidAt') ? undefined : FirebaseFirestoreTimestampFromJSON(json['paidAt']),
        'action': ActionFromJSON(json['action']),
    };
}

export function PaymentLinkToJSON(value?: PaymentLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reservationId': value.reservationId,
        'currencyCode': value.currencyCode,
        'paymentAccountId': PaymentAccountIdToJSON(value.paymentAccountId),
        'amount': value.amount,
        'url': value.url,
        'expireAt': FirebaseFirestoreTimestampToJSON(value.expireAt),
        'paidAt': FirebaseFirestoreTimestampToJSON(value.paidAt),
        'action': ActionToJSON(value.action),
    };
}

