import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import { notification, Spinner } from "../components";
import { chain, isError } from "lodash";
import { roles } from "../data-list";
import { useDocumentData } from "../hooks/firebase";
import assert from "assert";
import {
  browserLocalPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  User as FirebaseUser,
} from "firebase/auth";
import { useGlobalData } from "./GlobalDataProvider";

interface Context {
  authUser: AuthUser | null;
  login: (email: string, password: string) => Promise<void>;
  loginLoading: boolean;
  logout: () => Promise<void>;
}

const AuthenticationContext = createContext<Context>({
  authUser: null,
  login: () => Promise.reject("Unable to find AuthenticationProvider."),
  logout: () => Promise.reject("Unable to find AuthenticationProvider."),
  loginLoading: false,
});

interface Props {
  children: JSX.Element;
}

export const useAuthentication = (): Context =>
  useContext(AuthenticationContext);

export const AuthenticationProvider = ({ children }: Props): JSX.Element => {
  const [authenticating, setAuthenticating] = useState<boolean>(true);
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [firebaseAuthUser, setFirebaseAuthUser] = useState<FirebaseUser | null>(
    null
  );
  const [user, setUser] = useState<User | null>(null);
  const { operators } = useGlobalData();

  const [userSnapshot, userLoading, userError] = useDocumentData<User>(
    "users",
    firebaseAuthUser?.uid
  );

  const [roleAcls, roleAclsLoading, roleAclsError] = useDocumentData<RoleAcls>(
    "roles-acls",
    user?.roleCode
  );

  const [agency, agencyLoading, agencyError] = useDocumentData<Agency>(
    "agencies",
    user?.agency?.id
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseAuthUser) =>
      firebaseAuthUser ? setFirebaseAuthUser(firebaseAuthUser) : onLogout()
    );

    return () => unsubscribe();
  }, []);

  const error = userError || roleAclsError || agencyError;

  useEffect(() => {
    error && notification({ type: "error" });
  }, [error]);

  useEffect(() => {
    !userLoading && userSnapshot && onLogin(userSnapshot);
  }, [userLoading, userSnapshot?.id]);

  const onLogin = async (user: User) => {
    setUser(user);
    setLoginLoading(false);
    setAuthenticating(false);
  };

  const onLogout = async () => {
    setAuthenticating(true);
    setUser(null);
    setFirebaseAuthUser(null);
    setAuthenticating(false);
    setLoginLoading(false);
  };

  const login: Context["login"] = async (email, password) => {
    try {
      setLoginLoading(true);

      await setPersistence(auth, browserLocalPersistence);

      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      const error = isError(e) ? e : undefined;

      notification({
        type: "error",
        title: "Login error",
        description: error?.message,
      });

      setLoginLoading(false);
    }
  };

  const logout: Context["logout"] = async () => {
    sessionStorage.clear();
    localStorage.clear();
    await auth.signOut();
  };

  const loading =
    userLoading || roleAclsLoading || authenticating || agencyLoading;

  if (loading) return <Spinner fullscreen />;

  return (
    <AuthenticationContext.Provider
      value={{
        authUser: user ? mapAuthUser(user, operators, roleAcls, agency) : null,
        login,
        logout,
        loginLoading,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

const mapAuthUser = (
  user: User,
  operators: Operator[],
  roleAcls?: RoleAcls,
  agency?: Agency
): AuthUser => {
  const _operators = operatorsByUser(user, operators, agency);
  const role = roleByUser(user);
  assert(role, "role does not exist!");

  return {
    ...user,
    role,
    acls: roleAcls?.acls || [],
    operators: _operators,
    initialOperator: _operators[0],
    agency: agency || user.agency,
  };
};

const roleByUser = (user: User): Role | undefined =>
  roles.find((role) => role.code === user.roleCode);

const operatorsByUser = (
  user: User,
  operators: Operator[],
  agency?: Agency
): Operator[] =>
  chain(operators)
    .filter((operator) =>
      user.roleCode === "administrator"
        ? true
        : (agency?.operatorsIds || []).includes(operator.id)
    )
    .orderBy((operator) => operator.name, ["asc"])
    .value();
