/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Action } from './Action';
import {
    ActionFromJSON,
    ActionFromJSONTyped,
    ActionToJSON,
} from './Action';
import type { OpenpayToken } from './OpenpayToken';
import {
    OpenpayTokenFromJSON,
    OpenpayTokenFromJSONTyped,
    OpenpayTokenToJSON,
} from './OpenpayToken';

/**
 * 
 * @export
 * @interface PostTransactionBody
 */
export interface PostTransactionBody {
    /**
     * 
     * @type {OpenpayToken}
     * @memberof PostTransactionBody
     */
    token: OpenpayToken;
    /**
     * 
     * @type {Action}
     * @memberof PostTransactionBody
     */
    action: Action | null;
    /**
     * 
     * @type {string}
     * @memberof PostTransactionBody
     */
    deviceSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostTransactionBody
     */
    paymentLinkId?: string;
}

/**
 * Check if a given object implements the PostTransactionBody interface.
 */
export function instanceOfPostTransactionBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "action" in value;

    return isInstance;
}

export function PostTransactionBodyFromJSON(json: any): PostTransactionBody {
    return PostTransactionBodyFromJSONTyped(json, false);
}

export function PostTransactionBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostTransactionBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': OpenpayTokenFromJSON(json['token']),
        'action': ActionFromJSON(json['action']),
        'deviceSessionId': !exists(json, 'deviceSessionId') ? undefined : json['deviceSessionId'],
        'paymentLinkId': !exists(json, 'paymentLinkId') ? undefined : json['paymentLinkId'],
    };
}

export function PostTransactionBodyToJSON(value?: PostTransactionBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': OpenpayTokenToJSON(value.token),
        'action': ActionToJSON(value.action),
        'deviceSessionId': value.deviceSessionId,
        'paymentLinkId': value.paymentLinkId,
    };
}

