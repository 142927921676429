/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LightProductSetup } from './LightProductSetup';
import {
    LightProductSetupFromJSON,
    LightProductSetupFromJSONTyped,
    LightProductSetupToJSON,
} from './LightProductSetup';
import type { Photo } from './Photo';
import {
    PhotoFromJSON,
    PhotoFromJSONTyped,
    PhotoToJSON,
} from './Photo';

/**
 * 
 * @export
 * @interface LightProductContent
 */
export interface LightProductContent {
    /**
     * 
     * @type {Array<Photo>}
     * @memberof LightProductContent
     */
    photos?: Array<Photo>;
    /**
     * 
     * @type {LightProductSetup}
     * @memberof LightProductContent
     */
    productSetup: LightProductSetup;
}

/**
 * Check if a given object implements the LightProductContent interface.
 */
export function instanceOfLightProductContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productSetup" in value;

    return isInstance;
}

export function LightProductContentFromJSON(json: any): LightProductContent {
    return LightProductContentFromJSONTyped(json, false);
}

export function LightProductContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): LightProductContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'photos': !exists(json, 'photos') ? undefined : ((json['photos'] as Array<any>).map(PhotoFromJSON)),
        'productSetup': LightProductSetupFromJSON(json['productSetup']),
    };
}

export function LightProductContentToJSON(value?: LightProductContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'photos': value.photos === undefined ? undefined : ((value.photos as Array<any>).map(PhotoToJSON)),
        'productSetup': LightProductSetupToJSON(value.productSetup),
    };
}

