/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReservationCardFee
 */
export interface ReservationCardFee {
    /**
     * 
     * @type {string}
     * @memberof ReservationCardFee
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ReservationCardFee
     */
    from: number;
    /**
     * 
     * @type {number}
     * @memberof ReservationCardFee
     */
    to?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReservationCardFee
     */
    value: number;
}

/**
 * Check if a given object implements the ReservationCardFee interface.
 */
export function instanceOfReservationCardFee(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function ReservationCardFeeFromJSON(json: any): ReservationCardFee {
    return ReservationCardFeeFromJSONTyped(json, false);
}

export function ReservationCardFeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationCardFee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'from': json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'value': json['value'],
    };
}

export function ReservationCardFeeToJSON(value?: ReservationCardFee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'from': value.from,
        'to': value.to,
        'value': value.value,
    };
}

