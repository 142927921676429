import React from "react";
import styled, { css, DefaultTheme } from "styled-components";
import { isEmpty, toString } from "lodash";
import { classNames, keyframes } from "../styles";
import { Typography } from "antd";
import { lighten, tint } from "polished";
import { Moment } from "moment";

const { Text } = Typography;

interface Props extends Partial<StyledProps> {
  children?: React.ReactNode;
  helperText?: string;
  label?: React.ReactNode;
  value?:
    | Record<string, unknown>
    | null
    | []
    | boolean
    | string
    | string[]
    | number
    | Moment;
  dataTestId?: string;
  componentId?: string;
}

export const Filled = ({
  animation = false,
  hidden = false,
  children,
  disabled = false,
  error = false,
  helperText,
  label,
  required = false,
  value,
  dataTestId,
  componentId,
  bordered = true,
}: Props): JSX.Element => (
  <Wrapper hidden={hidden}>
    <Container
      hasValue={typeof value === "object" ? !isEmpty(value) : !!toString(value)}
      error={error}
      disabled={disabled}
      required={required}
      animation={animation}
      className={classNames({ "scroll-error-anchor": error })}
      data-testid={dataTestId}
      bordered={!!bordered}
    >
      <label htmlFor={componentId} className="item-label">
        {label}
      </label>
      <div className="item-wrapper">{children}</div>
    </Container>
    {helperText && (
      <ErrorItem
        fontColor={(theme) => (error ? theme.colors.error : undefined)}
        fontSize="small"
        error={error}
      >
        {helperText}
      </ErrorItem>
    )}
  </Wrapper>
);

type StyledProps = ContainerProps & WrapperProps;

interface ContainerProps {
  animation: boolean;
  disabled: boolean;
  error: boolean;
  required: boolean;
  hasValue: boolean;
  bordered: boolean;
}

interface WrapperProps {
  hidden: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  ${({ hidden }) => css`
    ${hidden &&
    css`
      display: none;
    `}
  `}
`;

const Container = styled.div<ContainerProps>`
  ${({ error, theme, disabled, required, bordered }) => css`
    position: relative;
    width: inherit;
    border-radius: ${theme.border_radius.xx_small};
   background: ${disabled ? "#f5f5f5" : theme.colors.white};
    ${
      bordered &&
      css`
        border: 1px solid ${error ? theme.colors.error : theme.colors.bordered};
      `
    }
    animation: ${
      error && keyframes.shake
    } 340ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    
    ${
      bordered &&
      css`
        &:hover,
        &:focus-within {
          border-color: ${error
            ? theme.colors.error
            : disabled
            ? lighten(0.04, theme.colors.bordered)
            : lighten(0.1, theme.colors.dark)};
        }

        .item-label,
        .item-label:after {
          color: ${error
            ? theme.colors.error
            : disabled
            ? theme.colors.dark
            : lighten(0.1, "#1a1a1a")};
        }

        &:focus-within {
          ${error
            ? css`
                border-color: ${theme.colors.error};
                box-shadow: 0 0 0 2px ${tint(0.85, theme.colors.error)};
              `
            : css`
                border-color: ${lighten(0.1, theme.colors.dark)};
                box-shadow: 0 0 0 2px ${tint(0.85, theme.colors.dark)};
              `}
        }
      `
    }
  }

  .item-label {
    line-height: 1;
    padding: 5px 11px 0 11px;
    z-index: 100;
      user-select: none;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: ${error ? theme.colors.error : "#1a1a1a"};
    font-size: ${theme.font_sizes.x_small};
    font-weight: 600;
    transition: all ease-in-out 150ms, opacity 150ms;

    ${
      required &&
      css`
        ::after {
          display: inline-block;
          margin-left: 0.2rem;
          color: ${error ? theme.colors.error : "#1a1a1a"};
          font-size: ${theme.font_sizes.small};
          line-height: 1;
          content: "*";
        }
      `
    }
  }

  .item-wrapper {
    &:focus-within + .item-label,
    &:-webkit-autofill + .item-label {
      color: ${error ? theme.colors.error : lighten(0.1, "#1a1a1a")};

      ${
        error &&
        css`
          color: ${theme.colors.error};
        `
      }
      &:after {
        color: ${error ? theme.colors.error : lighten(0.1, "#1a1a1a")};
      }
    }

    //Styles default
    .ant-input-number,
    .ant-picker,
    .ant-select {
      width: 100%;
      box-shadow: none;
      outline: none;
    }

    .ant-input-affix-wrapper,
    .ant-input {
      box-shadow: none;
    }
  }
  `}
`;

interface ErrorItemProps {
  fontColor?: (theme: DefaultTheme) => string | undefined;
  fontSize:
    | "xx_small"
    | "x_small"
    | "small"
    | "medium"
    | "large"
    | "x_large"
    | "xx_large"
    | "xxx_large";
  error: boolean;
}

const ErrorItem = styled(Text)<ErrorItemProps>`
  ${({ theme, fontColor, fontSize = "small", error }) => css`
    font-size: ${theme.font_sizes[fontSize]};
    ${fontColor &&
    css`
      color: ${fontColor(theme)};
    `},
    ${error &&
    css`
      animation: ${keyframes.shake} 340ms;
    `}
  `}
`;
