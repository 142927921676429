/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgePrice } from './AgePrice';
import {
    AgePriceFromJSON,
    AgePriceFromJSONTyped,
    AgePriceToJSON,
} from './AgePrice';
import type { Image } from './Image';
import {
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
} from './Image';
import type { Languages } from './Languages';
import {
    LanguagesFromJSON,
    LanguagesFromJSONTyped,
    LanguagesToJSON,
} from './Languages';

/**
 * 
 * @export
 * @interface OptionTimePricing
 */
export interface OptionTimePricing {
    /**
     * 
     * @type {string}
     * @memberof OptionTimePricing
     */
    id: string;
    /**
     * 
     * @type {Languages}
     * @memberof OptionTimePricing
     */
    name: Languages;
    /**
     * 
     * @type {boolean}
     * @memberof OptionTimePricing
     */
    onlyVisibleWhenAuthenticated?: boolean;
    /**
     * 
     * @type {Languages}
     * @memberof OptionTimePricing
     */
    description: Languages;
    /**
     * 
     * @type {Image}
     * @memberof OptionTimePricing
     */
    optionImage?: Image;
    /**
     * 
     * @type {number}
     * @memberof OptionTimePricing
     */
    maxTravelersPerOption: number | null;
    /**
     * 
     * @type {Array<AgePrice>}
     * @memberof OptionTimePricing
     */
    agePrices: Array<AgePrice>;
    /**
     * 
     * @type {string}
     * @memberof OptionTimePricing
     */
    time: string;
}

/**
 * Check if a given object implements the OptionTimePricing interface.
 */
export function instanceOfOptionTimePricing(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "maxTravelersPerOption" in value;
    isInstance = isInstance && "agePrices" in value;
    isInstance = isInstance && "time" in value;

    return isInstance;
}

export function OptionTimePricingFromJSON(json: any): OptionTimePricing {
    return OptionTimePricingFromJSONTyped(json, false);
}

export function OptionTimePricingFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTimePricing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': LanguagesFromJSON(json['name']),
        'onlyVisibleWhenAuthenticated': !exists(json, 'onlyVisibleWhenAuthenticated') ? undefined : json['onlyVisibleWhenAuthenticated'],
        'description': LanguagesFromJSON(json['description']),
        'optionImage': !exists(json, 'optionImage') ? undefined : ImageFromJSON(json['optionImage']),
        'maxTravelersPerOption': json['maxTravelersPerOption'],
        'agePrices': ((json['agePrices'] as Array<any>).map(AgePriceFromJSON)),
        'time': json['time'],
    };
}

export function OptionTimePricingToJSON(value?: OptionTimePricing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': LanguagesToJSON(value.name),
        'onlyVisibleWhenAuthenticated': value.onlyVisibleWhenAuthenticated,
        'description': LanguagesToJSON(value.description),
        'optionImage': ImageToJSON(value.optionImage),
        'maxTravelersPerOption': value.maxTravelersPerOption,
        'agePrices': ((value.agePrices as Array<any>).map(AgePriceToJSON)),
        'time': value.time,
    };
}

