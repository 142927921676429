/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HubSpot
 */
export interface HubSpot {
    /**
     * 
     * @type {string}
     * @memberof HubSpot
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HubSpot
     */
    contactId?: string;
}

/**
 * Check if a given object implements the HubSpot interface.
 */
export function instanceOfHubSpot(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function HubSpotFromJSON(json: any): HubSpot {
    return HubSpotFromJSONTyped(json, false);
}

export function HubSpotFromJSONTyped(json: any, ignoreDiscriminator: boolean): HubSpot {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'contactId': !exists(json, 'contactId') ? undefined : json['contactId'],
    };
}

export function HubSpotToJSON(value?: HubSpot | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'contactId': value.contactId,
    };
}

