/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Action } from './Action';
import {
    ActionFromJSON,
    ActionFromJSONTyped,
    ActionToJSON,
} from './Action';

/**
 * 
 * @export
 * @interface PostPaymentIntentTokenBody
 */
export interface PostPaymentIntentTokenBody {
    /**
     * 
     * @type {Action}
     * @memberof PostPaymentIntentTokenBody
     */
    action: Action | null;
    /**
     * 
     * @type {string}
     * @memberof PostPaymentIntentTokenBody
     */
    paymentLinkId?: string;
}

/**
 * Check if a given object implements the PostPaymentIntentTokenBody interface.
 */
export function instanceOfPostPaymentIntentTokenBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "action" in value;

    return isInstance;
}

export function PostPaymentIntentTokenBodyFromJSON(json: any): PostPaymentIntentTokenBody {
    return PostPaymentIntentTokenBodyFromJSONTyped(json, false);
}

export function PostPaymentIntentTokenBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPaymentIntentTokenBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': ActionFromJSON(json['action']),
        'paymentLinkId': !exists(json, 'paymentLinkId') ? undefined : json['paymentLinkId'],
    };
}

export function PostPaymentIntentTokenBodyToJSON(value?: PostPaymentIntentTokenBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': ActionToJSON(value.action),
        'paymentLinkId': value.paymentLinkId,
    };
}

