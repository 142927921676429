// Standard display formats
export const PUBLIC_DATE_FORMAT = "ddd, MMM DD, YYYY";
export const SHORT_PUBLIC_DATE_FORMAT = "DD MMM YYYY";
export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const MONTH_DATE_FORMAT = "MMM YYYY";

// Date and time combinations
export const DATETIME_FORMAT = "DD/MM/YYYY HH:mm";
export const DATETIME_12H_FORMAT = "DD/MM/YYYY HH:mm a";
export const COMPLETE_DATETIME_FORMAT = "DD/MM/YYYY HH:mm:ss a";

// ISO standard formats
export const ISO_DATE_FORMAT = "YYYY-MM-DD";
export const ISO_MONTH_DATE_FORMAT = "YYYY-MM";
