import {
  BaseAPI,
  Configuration,
  Middleware,
  RequestContext,
  ResponseContext,
} from "../openapi";
import { cloneDeep, invoke, isError, toString } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { currentConfig } from "../config";
import { getApiErrorResponse } from "./apiError";
import { useDomainProvider } from "../providers";
import { auth } from "../firebase";
import { getIdToken } from "firebase/auth";
import sha256 from "crypto-js/sha256";

// eslint-disable-next-line @typescript-eslint/ban-types
type MethodKeyOf<T extends object> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in keyof T]: T[K] extends (...args: any) => any ? K : never;
}[keyof T];

type MethodParametersOf<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object,
  K extends keyof T
> = T[K] extends (...args: infer _) => unknown ? _ : never;

type MethodReturnTypeOf<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object,
  K extends keyof T
> = Promise<
  // eslint-disable-next-line
  T[K] extends (...args: any[]) => any
    ? PromiseResolveType<ReturnType<T[K]>>
    : never
>;

type PromiseResolveType<T> = T extends Promise<infer _> ? _ : T;

type ClientConstructor<T> = { new (config: Configuration): T };

export type UseOpenApiHook<T extends BaseAPI, K extends MethodKeyOf<T>> = {
  run: (...params: MethodParametersOf<T, K>) => MethodReturnTypeOf<T, K>;
  loading: boolean;
  data?: PromiseResolveType<MethodReturnTypeOf<T, K>>;
  error?: string;
  errorData?: unknown;
  status?: number;
  abort: () => void;
  setData: React.Dispatch<
    React.SetStateAction<
      PromiseResolveType<MethodReturnTypeOf<T, K>> | undefined
    >
  >;
};

export const useOpenApi = <T extends BaseAPI, K extends MethodKeyOf<T>>(
  clientConstructor: ClientConstructor<T>,
  method: K,
  config?: {
    initialParams?: MethodParametersOf<T, K>;
    noCache?: boolean;
    deps?: unknown[];
  }
): UseOpenApiHook<T, K> => {
  const { domain } = useDomainProvider();

  const signalKey = toString(method);
  const ABORT_REQUEST_CONTROLLERS = new Map();

  const { initialParams, deps = [], noCache = true } = config || {};

  const [loading, setLoading] = useState(initialParams !== undefined);
  const [data, setData] = useState<
    PromiseResolveType<MethodReturnTypeOf<T, K>>
  >();
  const [error, setError] = useState<string>();
  const [status, setStatus] = useState<number>();
  const [errorData, setErrorData] = useState<unknown>();

  const dependency =
    initialParams?.length === 1 ? JSON.stringify(initialParams) : null;

  useEffect(() => {
    if (initialParams) {
      run(...initialParams).catch((e) => console.error(e));
    }
  }, [dependency, ...deps]);

  const headersPreMiddleware: Middleware["pre"] = useCallback(
    async (context: RequestContext) => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("webuild-domain", domain);
      headers.append(
        "x-client-performance",
        generateRequestSignature(context.url)
      );

      if (noCache) {
        headers.append("pragma", "no-cache");
        headers.append("cache-control", "no-cache");
      }

      if (auth.currentUser) {
        headers.append(
          "authorization",
          `Bearer ${await getIdToken(auth.currentUser)}`
        );
      }

      return {
        url: context.url,
        init: { ...context.init, headers: headers },
      };
    },
    []
  );

  const errorPostMiddleWare: Middleware["post"] = useCallback(
    async ({ response }: ResponseContext) => {
      setStatus(response.status);

      if (!response.ok) {
        const errorResponse = await getApiErrorResponse(cloneDeep(response));
        setErrorData(errorResponse);

        throw response;
      }
    },
    []
  );

  const getSignalSafe = useCallback((key: string): AbortSignal => {
    const newController = new AbortController();
    ABORT_REQUEST_CONTROLLERS.set(key, newController);
    return newController.signal;
  }, []);

  const abortRequestSafe = useCallback((key: string): void => {
    ABORT_REQUEST_CONTROLLERS.get(key)?.abort?.("CANCELLED");
  }, []);

  const abort = useCallback(() => {
    abortRequestSafe(signalKey);
  }, []);

  const run = useCallback(
    // @ts-ignore
    async (...params: MethodParametersOf<T, K>): MethodReturnTypeOf<T, K> => {
      const client = new clientConstructor(
        new Configuration({
          basePath: currentConfig.apiUrl,
          fetchApi: (requestInfo, requestInit) =>
            fetch(requestInfo, {
              ...requestInit,
              signal: getSignalSafe(signalKey),
            }),
        })
      );

      const invokePromise = invoke(
        client
          .withPreMiddleware(headersPreMiddleware)
          .withPostMiddleware(errorPostMiddleWare),
        method,
        ...params
      ) as Promise<PromiseResolveType<MethodReturnTypeOf<T, K>>>;

      setLoading(true);
      setError(undefined);
      setErrorData(undefined);
      setStatus(undefined);

      try {
        const result = await invokePromise;
        setData(result);
        return result;
      } catch (e) {
        if (isError(e) && e.name === "AbortError") {
          console.error("Abort fetch", { signalKey, error: e });
        } else {
          setError(`Error fetching of ${toString(method)}`);
          throw e;
        }
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { run, loading, data, error, errorData, status, abort, setData };
};

const generateRequestSignature = (url: string): string =>
  sha256(url.split("?")?.[0]).toString().split("").reverse().join().slice(0, 3);
