/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Host } from './Host';
import {
    HostFromJSON,
    HostFromJSONTyped,
    HostToJSON,
} from './Host';
import type { PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency } from './PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency';
import {
    PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgencyFromJSON,
    PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgencyFromJSONTyped,
    PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgencyToJSON,
} from './PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency';
import type { PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsCity } from './PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsCity';
import {
    PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsCityFromJSON,
    PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsCityFromJSONTyped,
    PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsCityToJSON,
} from './PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsCity';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    search?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsCity}
     * @memberof User
     */
    city: PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsCity | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    roleCode: string;
    /**
     * 
     * @type {PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency}
     * @memberof User
     */
    agency: PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    updateBy: string;
    /**
     * 
     * @type {Host}
     * @memberof User
     */
    host?: Host;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "roleCode" in value;
    isInstance = isInstance && "agency" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "updateBy" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'search': !exists(json, '_search') ? undefined : json['_search'],
        'id': json['id'],
        'city': PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsCityFromJSON(json['city']),
        'roleCode': json['roleCode'],
        'agency': PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgencyFromJSON(json['agency']),
        'email': json['email'],
        'password': json['password'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'updateBy': json['updateBy'],
        'host': !exists(json, 'host') ? undefined : HostFromJSON(json['host']),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '_search': value.search,
        'id': value.id,
        'city': PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsCityToJSON(value.city),
        'roleCode': value.roleCode,
        'agency': PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgencyToJSON(value.agency),
        'email': value.email,
        'password': value.password,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'updateBy': value.updateBy,
        'host': HostToJSON(value.host),
    };
}

