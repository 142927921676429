/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostReservationSendPaymentLinkBody
 */
export interface PostReservationSendPaymentLinkBody {
    /**
     * 
     * @type {string}
     * @memberof PostReservationSendPaymentLinkBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PostReservationSendPaymentLinkBody
     */
    paymentLinkId: string;
}

/**
 * Check if a given object implements the PostReservationSendPaymentLinkBody interface.
 */
export function instanceOfPostReservationSendPaymentLinkBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "paymentLinkId" in value;

    return isInstance;
}

export function PostReservationSendPaymentLinkBodyFromJSON(json: any): PostReservationSendPaymentLinkBody {
    return PostReservationSendPaymentLinkBodyFromJSONTyped(json, false);
}

export function PostReservationSendPaymentLinkBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostReservationSendPaymentLinkBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'paymentLinkId': json['paymentLinkId'],
    };
}

export function PostReservationSendPaymentLinkBodyToJSON(value?: PostReservationSendPaymentLinkBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'paymentLinkId': value.paymentLinkId,
    };
}

